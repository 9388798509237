import React from "react"
import PropTypes from "prop-types"
import getThemeColor from "../../utils/getThemeColor.js"

import * as S from "./styled"

const PostItem = ({ slug, background, category, date, timeToRead, title, description }) => (
    <S.PostItemLink cover direction="right" bg={getThemeColor()} duration={0.6} to={slug}>
        <S.PostItemWrapper>
            <S.PostItemInfo>
                <S.PostItemTag background={background}>{category}</S.PostItemTag>
                <S.PostItemTitle>
                    {title}
                </S.PostItemTitle>
                <S.PostItemDate>{date} - {timeToRead}min de leitura</S.PostItemDate>
                <S.PostItemDescription>
                    {description}
                </S.PostItemDescription>
            </S.PostItemInfo>
        </S.PostItemWrapper>
    </S.PostItemLink>
)

PostItem.propTypes = {
    slug: PropTypes.string.isRequired,
    background: PropTypes.string,
    category: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    timeToRead: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
}

export default PostItem